<template>
  <div>    
    <div class="vx-row">
      <div class="vx-col w-full  mt-8">
        <vx-card>
          <div class="card-title">
            <h2>Available Reports</h2>            
          </div> 
          <div class="report-list">
            <ul>
              <!-- Customer Activity Report -->
              <li>
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Customer Activity Report</h3>
                  <p>This report will show the activity and status of each customer. Use this report to receive a summary of  activity from each of your customers. The date range is set to one week by default.</p>
                  <div class="base-btn">
                    <router-link :to="{name: 'Customer Activity Report', params: { uid: uid }}">
                      <vs-button color="primary" type="filled" >{{ LabelConstant.buttonLabelRunInactiveCustomerActivityReport }}</vs-button>
                    </router-link>
                  </div>
                </div>
              </li>
              <!-- Inactive Customer Summary Report -->
              <li>
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Inactive Customer Summary Report</h3>
                  <p>This report will show all accounts that have not shown any logging activity in the past 30 days. Use this report to determine which customers have properly installed this service on their website.</p>
                  <div class="base-btn">
                    <router-link :to="{name: 'Customer Inactive Report', params: { uid: uid }}">
                      <vs-button color="primary" type="filled">{{ LabelConstant.buttonLabelRunInactiveCustomerSummaryReport }}</vs-button>
                    </router-link>
                  </div>
                </div>
              </li>
              <!-- Consumer Customer Activity Report -->
              <li>
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Consumer (+Person) Customer Activity Report</h3>
                  <p>This report will show all customers with a consumer plan . Use this report to view the plan settings and current count of unique identified visitors for each customer.</p>
                  <div class="base-btn">
                    <router-link :to="{name: 'Consumer Customer Activity Report', params: { uid: uid }}">
                      <vs-button color="primary" type="filled" >{{ LabelConstant.buttonLabelRunConsumerCustomerActivityReport }}</vs-button>
                    </router-link>
                  </div>
                </div>
              </li>
              <!-- Consumer Reseller Detail Report -->
              <li v-if="initialRole =='Global Admin'">
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Consumer (+Person) Reseller Detail Report</h3>
                  <p>This report will show a list of all reseller customers with a consumer plan . Use this to view a summary of the plan setting and current count of unique identified visitors for each reseller's customer.</p>
                  <div class="base-btn">
                    <router-link :to="{name: 'Consumer Reseller Detail Report', params: { uid: uid }}">
                      <vs-button color="primary" type="filled">{{ LabelConstant.buttonLabelRunConsumerResellerDetailReport }}</vs-button>
                    </router-link>
                  </div>
                </div>
              </li>
              <!-- Consumer Reseller Summary Report -->
              <li v-if="initialRole =='Global Admin'">
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Consumer (+Person) Reseller Summary Report</h3>
                  <p>This report will show a list of all resellers having customers with a consumer plan . Use this report to view the count of unique identified visitors for each reseller's combine customer's activity.</p>
                  <div class="base-btn">
                    <router-link :to="{name: 'Consumer Reseller Summary Report', params: { uid: uid }}">
                      <vs-button color="primary" type="filled" >{{ LabelConstant.buttonLabelRunReport }}</vs-button>
                    </router-link>
                  </div>
                </div>
              </li>          
            </ul>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import VueJwtDecode from "vue-jwt-decode";

export default {
  components: {
    VxCard,
    VueJwtDecode  
  },
  data(){
    return {
      uid: this.$route.params.id,
      initialRole:'',
    }
  },
  mounted() {
    let jwtDecode = VueJwtDecode.decode(
      this.$store.state.authTokenVV[this.uid].token
    );
    this.initialRole = jwtDecode.initialRole;
  },
};
</script>
